import { STEPS, Step } from '../../metadata/constants';
import { FormData } from '../../metadata/forms';

export function getStepsToSkip(formData: FormData): Step[] {
  const { is_home_owned, current_flow, finance_type, trust } = formData;
  const commonSteps = [];

  if (is_home_owned) {
    commonSteps.push(STEPS.LIENS_BALANCES);
  }

  if (trust?.owner_names?.length > 0) {
    commonSteps.push(STEPS.TRUST);
  }

  if (current_flow === 'lender') {
    return [
      ...commonSteps,
      STEPS.LOAN_OFFICER_INFORMATION,
      STEPS.FINANCIAL_TYPE,
    ];
  }

  if (current_flow === 'agent') {
    if (finance_type === 'cash') {
      return [
        ...commonSteps,
        STEPS.FINANCIAL_INFORMATION,
        STEPS.AGENT_INFORMATION,
      ];
    }
    return [...commonSteps, STEPS.AGENT_INFORMATION];
  }

  return [];
}
