import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Paragraph } from '@hl-portals/ui';

import { useModal } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { ReviewEligibilityStatusModal } from '../../review-eligibility-status-modal';

export const CreditScore = () => {
  const { onUpdateSnapshot, isLoading } = useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  const { openModal, closeModal, isModalOpened } = useModal();
  const { is_credit_score_above_620 } = watch();

  const handleSelectYes = () => {
    setValue('is_credit_score_above_620', true);
    onUpdateSnapshot({ is_credit_score_above_620: true });
  };

  const handleSelectNo = () => {
    if (is_credit_score_above_620 === false) {
      onUpdateSnapshot({ is_credit_score_above_620: false });
    } else {
      setValue('is_credit_score_above_620', false);
    }
  };

  useEffect(() => {
    if (is_credit_score_above_620 === false && !isModalOpened) {
      openModal(
        <ReviewEligibilityStatusModal
          onClose={closeModal}
          onConfirm={() => {
            onUpdateSnapshot();
            closeModal();
          }}
          title={
            <Paragraph fontWeight="600" pb="4px">
              Low credit score
            </Paragraph>
          }
          description={
            <Paragraph fontWeight="400">
              To approve the equity loan, at least 1 title owner must have a
              credit score of 620 or higher. However, if your client qualifies
              for financing on the incoming residence, they can still remove the
              existing mortgage from the DTI calculation and submit offers
              without a home sale contingency.
            </Paragraph>
          }
          renderFootnote
        />
      );
    }
  }, [is_credit_score_above_620]);

  return (
    <>
      <Content>
        <Container
          title="Is your client’s credit score above 620?"
          subtitle="Only one title holder needs to meet this requirement."
        >
          <Box mb="20px" flexDirection="column" gap="16px">
            <CardRadio
              title="Yes"
              onClick={() => handleSelectYes()}
              isActive={is_credit_score_above_620 === true}
              justifyContent="center"
            />
            <CardRadio
              title="No"
              isActive={is_credit_score_above_620 === false}
              onClick={() => handleSelectNo()}
              justifyContent="center"
            />
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            The Equity Unlock loan requires a minimum credit score. If not met,
            your client may still be eligible for other program benefits.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() => onUpdateSnapshot()}
          disabled={is_credit_score_above_620 === undefined}
        />
      </Footer>
    </>
  );
};
