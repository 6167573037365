import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs, Paragraph, Tooltip } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

const TotalCashToCloseTooltip = () => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box justifyContent="flex-start" flexDirection="column" minWidth="210px">
        <ul style={{ margin: 0 }}>
          <li>
            <Paragraph color="white" variant="text-small">
              The total amount your client will bring to closing.
            </Paragraph>
          </li>
          <li>
            <Paragraph color="white" variant="text-small">
              HomeLight & Additional funds.
            </Paragraph>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

const EquityUnlockLoanTooltip = () => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box justifyContent="flex-start" flexDirection="column" minWidth="210px">
        <ul style={{ margin: 0 }}>
          <li>
            <Paragraph color="white" variant="text-small">
              The target loan amount needed from HomeLight.
            </Paragraph>
          </li>
          <li>
            <Paragraph color="white" variant="text-small">
              If your client doesn’t need the loan enter 0.
            </Paragraph>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export const NewHomePurchaseFunds = () => {
  const [inputFocus, setInputFocus] = useState('');
  const { onUpdateSnapshot } = useLeadSubmissionContext();

  const { watch } = useFormContext();
  const { new_home_total_cash_to_close, new_home_target_equity_unlock_amount } =
    watch();

  const disable =
    !new_home_total_cash_to_close || !new_home_target_equity_unlock_amount;

  const renderCashTooltip = () => {
    return (
      <Tooltip
        direction="right"
        node={<TotalCashToCloseTooltip />}
        offset={{ right: 8 }}
      >
        <Inputs.Currency
          isFocused
          border="transparent"
          name="new_home_total_cash_to_close"
          maxWidth="192px"
        />
      </Tooltip>
    );
  };

  const renderEquityUnlockTooltip = () => {
    return (
      <Tooltip
        direction="right"
        node={<EquityUnlockLoanTooltip />}
        offset={{ right: 8 }}
      >
        <Inputs.Currency
          isFocused
          border="transparent"
          name="new_home_target_equity_unlock_amount"
          maxWidth="192px"
        />
      </Tooltip>
    );
  };

  return (
    <>
      <Content>
        <Container title="Tell us about the new home purchase">
          <Box
            mb="20px"
            flexDirection="column"
            gap="16px"
            onMouseLeave={() => setInputFocus('')}
          >
            <Box alignItems="center" justifyContent="space-between">
              <Paragraph>Total Cash to Close</Paragraph>
              <Box display="flex">
                {inputFocus === 'new_home_total_cash_to_close' ? (
                  renderCashTooltip()
                ) : (
                  <Inputs.Currency
                    border="transparent"
                    name="new_home_total_cash_to_close"
                    maxWidth="192px"
                    onFocus={() =>
                      setInputFocus('new_home_total_cash_to_close')
                    }
                  />
                )}
              </Box>
            </Box>
            <Box alignItems="center" justifyContent="space-between">
              <Paragraph>Equity Unlock Loan Amount</Paragraph>
              <Box display="flex">
                {inputFocus === 'new_home_target_equity_unlock_amount' ? (
                  renderEquityUnlockTooltip()
                ) : (
                  <Inputs.Currency
                    border="transparent"
                    name="new_home_target_equity_unlock_amount"
                    maxWidth="192px"
                    onFocus={() =>
                      setInputFocus('new_home_target_equity_unlock_amount')
                    }
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            Understanding the total amount your client will bring to closing
            help us to speed up the process.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() =>
            onUpdateSnapshot({
              new_home_total_cash_to_close,
              new_home_target_equity_unlock_amount,
            })
          }
          disabled={disable}
        />
      </Footer>
    </>
  );
};
