import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Paragraph } from '@hl-portals/ui';

import { useModal } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { ReviewEligibilityStatusModal } from '../../review-eligibility-status-modal';

export const AgeRestrictedCommunity = () => {
  const { onUpdateSnapshot, isLoading, onSetFlowError } =
    useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  const { openModal, closeModal, isModalOpened } = useModal();
  const { is_age_restricted, trust, dr_held_in_trust_userselect } = watch();

  const onSelect = (selection: boolean) => {
    setValue('is_age_restricted', selection);
    if (selection) {
      onSetFlowError('dr_in_age_restricted_community');
      return;
    }
    onUpdateSnapshot({ is_age_restricted: selection });
  };

  useEffect(() => {
    if (
      (trust.dr_held_in_trust || dr_held_in_trust_userselect) &&
      !isModalOpened
    ) {
      openModal(
        <ReviewEligibilityStatusModal
          onClose={closeModal}
          onConfirm={closeModal}
          title={
            <Paragraph fontWeight="600" pb="4px">
              Property held in a trust
            </Paragraph>
          }
          description={
            <Paragraph fontWeight="400">
              Our program is not available for properties held in an{' '}
              <strong>irrevocable trust</strong>. For other types of trusts,
              proceed with your application as usual. We will require trust
              documents for review prior to final approval.
            </Paragraph>
          }
        />
      );
    }
  }, [trust]);

  return (
    <>
      <Content>
        <Container
          title="Is departing residence located in an age restricted community?"
          subtitle="For example a 55+ retirement community."
        >
          <Box mb="20px" flexDirection="column" gap="16px">
            <CardRadio
              title="Yes"
              onClick={() => onSelect(true)}
              isActive={is_age_restricted === true}
              justifyContent="center"
            />
            <CardRadio
              title="No"
              isActive={is_age_restricted === false}
              onClick={() => onSelect(false)}
              justifyContent="center"
            />
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            Buy Before You Sell is currently not available for age-restricted
            communities.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() => onUpdateSnapshot()}
          disabled={is_age_restricted === undefined}
        />
      </Footer>
    </>
  );
};
