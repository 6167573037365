import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Paragraph } from '@hl-portals/ui';

import { useModal } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const NewHomeUnderContract = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  const { is_home_under_contract } = watch();

  const handleSelectYes = () => {
    setValue('is_home_under_contract', true);
    onUpdateSnapshot({ is_home_under_contract: true });
  };

  return (
    <>
      <Content>
        <Container title="Is your client under contract on a new home?">
          <Box mb="20px" flexDirection="column" gap="16px">
            <CardRadio
              title="Yes"
              onClick={() => handleSelectYes()}
              isActive={is_home_under_contract === true}
              justifyContent="center"
            />
            <CardRadio
              title="No"
              isActive={is_home_under_contract === false}
              onClick={() => {}}
              justifyContent="center"
            />
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            We use this information to determine the next steps and understand
            any deadlines related to the new home purchase.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() => onUpdateSnapshot()}
          disabled={is_home_under_contract === undefined}
        />
      </Footer>
    </>
  );
};
