import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Paragraph } from '@hl-portals/ui';

import { useModal } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { FINANCE_OPTIONS, FinanceOptions } from '../../../metadata/constants';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { ReviewEligibilityStatusModal } from '../../review-eligibility-status-modal';

export const ClientFinance = () => {
  const { onUpdateSnapshot, isLoading } = useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  const { openModal, closeModal, isModalOpened } = useModal();
  const { financing } = watch();

  const handleSelect = (selection: FinanceOptions) => {
    setValue('financing', selection);
    const shouldGoNext =
      selection === 'all_cash' ||
      selection === 'mortgage_conventional' ||
      selection === 'other';

    if (shouldGoNext) {
      onUpdateSnapshot({ financing: selection });
    }
  };

  useEffect(() => {
    if (
      (financing === 'mortgage_fha' || financing === 'mortgage_va') &&
      !isModalOpened
    ) {
      openModal(
        <ReviewEligibilityStatusModal
          onClose={closeModal}
          onConfirm={() => {
            onUpdateSnapshot({ financing });
            closeModal();
          }}
          title={
            <Paragraph fontWeight="600" pb="4px">
              Debt to Income affected
            </Paragraph>
          }
          description={
            <Paragraph fontWeight="400">
              The existing mortgage will need to be included in the Debt to
              Income calculation.
            </Paragraph>
          }
          renderFootnote
        />
      );
    }
  }, [financing]);

  return (
    <>
      <Content>
        <Container title="How is your client financing their new home?">
          <Box mb="20px" flexDirection="column" gap="16px">
            {FINANCE_OPTIONS.map(({ type, label }) => (
              <CardRadio
                title={label}
                onClick={() => handleSelect(type)}
                isActive={financing === type}
              />
            ))}
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            It's important for us to know about the financing of the incoming
            residence because it can affect the program.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton onClick={() => onUpdateSnapshot()} disabled={!financing} />
      </Footer>
    </>
  );
};
