import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const NewHomeClosingDate = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();

  const { watch } = useFormContext();
  const { new_property_close_of_escrow_date } = watch();

  const disable =
    new_property_close_of_escrow_date === undefined ||
    new_property_close_of_escrow_date === 'Invalid Date';

  return (
    <>
      <Content>
        <Container title="What is the closing date on the new home?">
          <Box mb="20px" flexDirection="column" gap="16px">
            <Inputs.Date
              label="Closing date (COE)"
              name="new_property_close_of_escrow_date"
            />
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            The incoming residence details will help us to speed up the
            application review.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() =>
            onUpdateSnapshot({ new_property_close_of_escrow_date })
          }
          disabled={disable}
        />
      </Footer>
    </>
  );
};
