import { useFormContext } from 'react-hook-form';

import { Alert, Box } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const Trust = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();
  const { watch, setValue } = useFormContext();

  const { dr_held_in_trust_userselect } = watch();
  const userTrustSelection = dr_held_in_trust_userselect;

  const onSelectDRinTrust = (selection: boolean | null) => {
    setValue('dr_held_in_trust_userselect', selection);
    onUpdateSnapshot({ dr_held_in_trust_userselect: selection });
  };

  return (
    <>
      <Content>
        <Container title="Is the departing residance held in a trust?">
          <Box mb="20px" flexDirection="column" gap="16px">
            <CardRadio
              title="Yes"
              onClick={() => onSelectDRinTrust(true)}
              isActive={userTrustSelection === true}
            />
            <CardRadio
              title="No"
              isActive={userTrustSelection === false}
              onClick={() => onSelectDRinTrust(false)}
            />
            <CardRadio
              title="Unsure"
              isActive={userTrustSelection === null}
              onClick={() => onSelectDRinTrust(null)}
            />
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            If the departing residence is in an{' '}
            <strong>irrevocable trust</strong> it will not be eligible for the
            program.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() => onUpdateSnapshot()}
          disabled={dr_held_in_trust_userselect === undefined}
        />
      </Footer>
    </>
  );
};
